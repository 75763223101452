var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "error" }, [
    _c("h1", [_vm._v("Error Occurred")]),
    _c("p", { staticClass: "error-title" }, [
      _vm._v(
        " We are experiencing issues with the Case Documentation module. "
      ),
    ]),
    _c("p", { staticClass: "error-title" }, [
      _vm._v("Please contact your system administrator."),
    ]),
    _c("p", [
      _c("span", { staticClass: "error-text" }, [
        _c("table", [
          _c("tr", [
            _c("td", { staticClass: "error-key" }, [_vm._v("Details:")]),
            _c("td", [_vm._v(" " + _vm._s(_vm.getError.message) + " ")]),
          ]),
          _c("tr", [
            _c("td", { staticClass: "error-key" }, [_vm._v("URL:")]),
            _c("td", [_vm._v(" " + _vm._s(_vm.getError.url) + " ")]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }