import Vue from "vue";
import Vuex from "vuex";
import BaseApi from "@/api/api";
import * as Constants from "@/constants/misc-constants";
import * as Errors from "@/constants/errors";
import { EHR_PROVIDERS as EhrProviders } from "@/constants/providers";
import { CASE_DOC_VIEWS as CaseDocumentationViews } from "@/constants/case-doc-views";
import {
    ACTIONS as StoreActions,
    MUTATIONS as StoreMutations
} from "@/constants/store-types";
import {
    handleErrorFromEhrSessionCreator,
    handleErrorFromEhrApi,
    toEhrError
} from "@/utils/error-util";
import { getTenantFromTokenResponse } from "@/utils/tenant-util";
import { getCaseDocumentationViewFromTokenResponse } from "@/utils/view-util";
import { extractEncounterId } from "@/utils/encounter-util";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        provider: EhrProviders.CERNER,
        loginSuccessful: false,
        idToken: "",
        accessToken: "",
        tenantId: 0,
        caseDocView: CaseDocumentationViews.OR,
        encounterId: 0,
        caseId: 0,
        environmentUrl: "",
        errorDetails: {},
        sendAccessToken: false,
        version: "v1"
    },
    mutations: {
        SET_HTTP_ERROR(state, payload) {
            state.errorDetails = payload;
        },
        SET_TENANT_AND_TOKENS(state, payload) {
            state.idToken = payload.idToken;
            state.accessToken = payload.accessToken;
            state.tenantId = payload.tenantId;
        },
        SET_URL(state, payload) {
            state.environmentUrl = payload.ItopiaUrl;
            state.applicationServerType = payload.ApplicationServerType;
        },
        SET_LOGIN_SUCCESSFUL(state, payload) {
            state.loginSuccessful = true;
            state.sessionId = payload;
        },
        SET_CASE_DOC_VIEW(state, payload) {
            state.caseDocView = payload;
        },
        SET_ENCOUNTER_ID(state, payload) {
            state.encounterId = payload;
        },
        SET_PROVIDER(state, payload) {
            state.provider = payload;
        },
        SET_CASE_ID(state, payload) {
            state.caseId = payload;
        },
        SET_SEND_ACCESS_TOKEN(state, payload) {
            state.sendAccessToken = payload;
        },
        SET_VERSION(state, payload) {
            state.version = payload;
        }
    },
    actions: {
        readEncounterFromFhirApi(context, payload) {
            const smart = payload;
            // Read from FHIR resource...
            return (
                smart.encounter
                    .read()
                    .then((encounter) => {
                        const encounterId = extractEncounterId(
                            context,
                            encounter
                        );
                        if (encounterId) {
                            context.commit(
                                StoreMutations.SET_ENCOUNTER_ID,
                                encounterId
                            );
                            return Promise.resolve();
                        } else {
                            return Promise.reject(
                                Errors.UNABLE_TO_FIND_ENCOUNTER
                            );
                        }
                    })
                    // If we got an error from the FHIR resource, bubble it up.
                    .catch((err) => {
                        return Promise.reject(err);
                    })
            );
        },
        setTenantAndTokens(context, payload) {
            const tokenAndTenant = {
                idToken: payload.id_token,
                accessToken: payload.access_token,
                tenantId: getTenantFromTokenResponse(
                    context.getters.provider,
                    payload
                )
            };
            return new Promise((resolve) => {
                context.commit(
                    StoreMutations.SET_TENANT_AND_TOKENS,
                    tokenAndTenant
                );
                resolve();
            });
        },
        fetchItopiaUrl(context, tenantRegistryUrl) {
            return BaseApi.get(tenantRegistryUrl, {})
                .then((response) =>
                    context.commit(StoreMutations.SET_URL, response)
                )
                .then(() =>
                    context.commit(StoreMutations.SET_SEND_ACCESS_TOKEN, true)
                )
                .then(() => context.dispatch(StoreActions.ITOPIA_LOGIN))
                .catch((err) => {
                    throw handleErrorFromEhrApi(err);
                });
        },
        itopiaLogin(context) {
            const baseUrl = context.getters.environmentUrl.replace(/\/$/, "");
            // Handle call to EHR Session Creator
            let url = `${baseUrl}/ehrsc/session`;
            return BaseApi.get(url, {}, { withCredentials: true })
                .then((data) => {
                    if (data && data !== "" && !/error/g.test(data)) {
                        context.commit(
                            StoreMutations.SET_LOGIN_SUCCESSFUL,
                            data
                        );
                    } else if (/error/g.test(data)) {
                        throw toEhrError(data);
                    } else {
                        throw toEhrError(Errors.UNKNOWN_INTERNAL_ERROR);
                    }
                })
                .catch((err) => {
                    throw handleErrorFromEhrSessionCreator(err);
                });
        },
        setCaseDocumentationView(context, payload) {
            try {
                const caseDocView = getCaseDocumentationViewFromTokenResponse(
                    context.getters.provider,
                    payload
                );
                context.commit(StoreMutations.SET_CASE_DOC_VIEW, caseDocView);
            } catch (err) {
                const contextVarError = toEhrError(
                    `${err}, ${Errors.DEFAULTING_TO_OR} ${Errors.PLEASE_CONTACT_SYS_ADMIN}`,
                    Constants.NOT_APPLICABLE,
                    Errors.ERROR_LEVELS.WARNING
                );
                context.commit(StoreMutations.SET_HTTP_ERROR, contextVarError);
            }
            return Promise.resolve();
        },
        setProvider(context, payload) {
            if (payload && payload === Constants.EPIC) {
                context.commit(StoreMutations.SET_PROVIDER, EhrProviders.EPIC);
            } else {
                context.commit(
                    StoreMutations.SET_PROVIDER,
                    EhrProviders.CERNER
                );
            }
            return Promise.resolve();
        },
        setVersion(context, payload) {
            if (payload && payload !== "undefined") {
                context.commit(StoreMutations.SET_VERSION, payload);
            }
            return Promise.resolve();
        },
        setCaseId(context, payload) {
            if (payload) {
                context.commit(StoreMutations.SET_CASE_ID, payload);
            }
            return Promise.resolve();
        }
    },
    modules: {},
    getters: {
        loginSuccessful: (state) => state.loginSuccessful,
        encounterId: (state) => state.encounterId,
        tenantId: (state) => state.tenantId,
        accessToken: (state) => state.accessToken,
        environmentUrl: (state) => state.environmentUrl,
        idToken: (state) => state.idToken,
        caseDocView: (state) => state.caseDocView,
        errorDetails: (state) => state.errorDetails,
        sessionId: (state) => state.sessionId,
        applicationServerType: (state) => state.applicationServerType,
        provider: (state) => state.provider,
        caseId: (state) => state.caseId,
        sendAccessToken: (state) => state.sendAccessToken,
        version: (state) => state.version
    }
});
