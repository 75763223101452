import {
    ACTIONS as StoreActions,
    MUTATIONS as StoreMutations
} from "@/constants/store-types";
import * as Constants from "@/constants/misc-constants";
import * as Errors from "@/constants/errors";
import { toEhrError } from "@/utils/error-util";
import { tcsIcon } from "tecsys-design-system";

export default {
    name: "itopia-main",
    components: {
        tcsIcon
    },
    created() {
        this.fetchUrl();
    },
    methods: {
        fetchUrl: function () {
            const ehrApiUrl =
                process.env.VUE_APP_TENANT_REGISTRY_URL_GEN.replace(
                    Constants.TENANT_ID_PLACEHOLDER,
                    this.$store.getters.tenantId
                );

            this.$store
                .dispatch(StoreActions.FETCH_ITOPIA_URL, ehrApiUrl)
                .catch((resp) => {
                    this.$store.commit(
                        StoreMutations.SET_HTTP_ERROR,
                        toEhrError(resp)
                    );
                    this.$router.push("/500");
                });
        }
    },
    computed: {
        displayWarning: function () {
            return (
                this.$store.getters.loginSuccessful === true &&
                this.$store.getters.errorDetails &&
                this.$store.getters.errorDetails.level ==
                    Errors.ERROR_LEVELS.WARNING
            );
        },
        getWarning: function () {
            return this.$store.getters.errorDetails;
        },
        itopiaReady: function () {
            return this.$store.getters.loginSuccessful === true;
        },
        itopiaEndpoint: function () {
            // Determine search criteria for Case (use Encounter or Case & Encounter)
            const searchCriteria = this.caseId
                ? Constants.ORDER_NUM
                : Constants.CONSUMER_CODE;
            const searchValue = this.caseId
                ? this.caseId + Constants.ENCODED_SEPARATOR + this.encounterId
                : this.encounterId;

            // Construct URL
            return `${this.envUrl}/itopia/portal/home?s=${this.$store.getters.sessionId}&resourceName_1=${this.resourceName}&criteria=${searchCriteria}/${searchValue}`;
        },
        envUrl() {
            return this.$store.getters.environmentUrl;
        },
        caseId() {
            return this.$store.getters.caseId;
        },
        encounterId() {
            return this.$store.getters.encounterId;
        },
        resourceName() {
            return this.$store.getters.caseDocView;
        }
    }
};
