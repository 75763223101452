import * as Errors from "@/constants/errors";
import * as Constants from "@/constants/misc-constants";
import { sanitizeCaseId } from "@/utils/string-util";
import { tcsIcon } from "tecsys-design-system";

function getClientId(provider, version) {
    if (
        version &&
        version !== Constants.VERSION_1 &&
        version !== Constants.VERSION_2
    ) {
        alert(Errors.VERSION_NOT_SUPPORTED);
    }

    if (provider === Constants.CERNER) {
        if (version && version === Constants.VERSION_2) {
            return process.env.VUE_APP_CLIENT_ID_CERNER_V2;
        }
        return process.env.VUE_APP_CLIENT_ID_CERNER;
    }

    if (provider === Constants.EPIC) {
        if (version && version === Constants.VERSION_2) {
            return process.env.VUE_APP_CLIENT_ID_EPIC_V2;
        }
        return process.env.VUE_APP_CLIENT_ID_EPIC;
    }

    alert(Errors.PROVIDER_NOT_SUPPORTED);
}

function getRedirectUri() {
    return window.location.href.split("launch")[0] + "callback";
}

function getPermissionScope(version) {
    if (!version || version === Constants.VERSION_1) {
        return process.env.VUE_APP_PERMISSION_SCOPE;
    }
    return process.env.VUE_APP_PERMISSION_SCOPE_V2;
}

function epicClientIdShouldBeSentInHeader() {
    /* If no such environment variable exists, just send the header anyway.
     * If an environment variable is provided, it must be true. */
    return (
        !process.env.VUE_APP_SEND_EPIC_CLIENT_ID_HEADER ||
        (process.env.VUE_APP_SEND_EPIC_CLIENT_ID_HEADER &&
            process.env.VUE_APP_SEND_EPIC_CLIENT_ID_HEADER === "true")
    );
}

function isCernerPowerChart() {
    return (
        window.external &&
        typeof window.external.DiscernObjectFactory !== "undefined"
    );
}

function setCaseIdInBrowserStorage(queryParams) {
    if (queryParams.has(Constants.EPIC_CASE_IDENTIFIER)) {
        const caseId = sanitizeCaseId(
            queryParams.get(Constants.EPIC_CASE_IDENTIFIER)
        );

        window.sessionStorage.setItem(Constants.EPIC_CASE_IDENTIFIER, caseId);
    } else {
        window.sessionStorage.removeItem(Constants.EPIC_CASE_IDENTIFIER);
    }
}

export default {
    name: "callback",
    props: ["version", "provider"],
    components: {
        tcsIcon
    },
    async mounted() {
        window.sessionStorage.setItem(Constants.PROVIDER, this.provider);
        window.sessionStorage.setItem(Constants.VERSION, this.version);
        this.$FHIR.oauth2.settings.fullSessionStorageSupport =
            !isCernerPowerChart();

        const queryParams = new URLSearchParams(window.location.search);
        setCaseIdInBrowserStorage(queryParams);

        let authorizePayLoad = {
            client_id: getClientId(this.provider, this.version),
            // If your redirect URL is not relative to your launch URL, it must be specified.
            redirectUri: getRedirectUri(),
            scope: getPermissionScope(this.version)
        };

        // If this is an Epic launch:
        if (
            this.provider === Constants.EPIC &&
            epicClientIdShouldBeSentInHeader()
        ) {
            authorizePayLoad.smartEndpointRequestOptions = {
                headers: { "Epic-Client-Id": getClientId(this.provider) }
            };
        }

        this.$FHIR.oauth2.authorize(authorizePayLoad);
    }
};
