<template>
    <div class="error">
        <h1>Error Occurred</h1>
        <p class="error-title">
            We are experiencing issues with the Case Documentation module.
        </p>
        <p class="error-title">Please contact your system administrator.</p>
        <p>
            <span class="error-text">
                <table>
                    <tr>
                        <td class="error-key">Details:</td>
                        <td>
                            {{ getError.message }}
                        </td>
                    </tr>
                    <tr>
                        <td class="error-key">URL:</td>
                        <td>
                            {{ getError.url }}
                        </td>
                    </tr>
                </table>
            </span>
        </p>
    </div>
</template>
<script>
export default {
    name: "error",
    computed: {
        getError: function () {
            return this.$store.state.errorDetails;
        }
    }
};
</script>
<style scoped>
.error-title {
    color: #cc0033;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: bold;
    margin-top: 2px;
    margin-bottom: 0;
}
.error {
    width: 80%;
    margin: 10px 0px;
    padding: 15px 10px 15px 50px;
    background-repeat: no-repeat;
    background-position: 10px center;
    background-color: #fce4e4;
    border: 1px solid #fcc2c3;
    overflow: auto;
}
.error-text {
    color: #cc0033;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: bold;
    line-height: 20px;
    text-shadow: 1px 1px rgba(250, 250, 250, 0.3);
}
.error-key {
    width: 10%;
}
table {
    width: 95%;
    table-layout: fixed;
}
tr td {
    padding-bottom: 10px;
    vertical-align: top;
    word-wrap: break-word;
}
</style>
