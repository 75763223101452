import {
    ACTIONS as StoreActions,
    MUTATIONS as StoreMutations
} from "@/constants/store-types";
import { toEhrError } from "@/utils/error-util";
import * as Constants from "@/constants/misc-constants";

export default {
    name: "callback",
    async mounted() {
        try {
            // On response back from the FHIR app authorization...
            const smart = await this.$FHIR.oauth2.ready();

            // Set version in store, if not specified, we assume v1
            const version = window.sessionStorage.getItem(Constants.VERSION);
            await this.$store.dispatch(StoreActions.SET_VERSION, version);

            // Determine the EHR software itself (provider), set in store
            const provider = window.sessionStorage.getItem(Constants.PROVIDER);
            await this.$store.dispatch(StoreActions.SET_PROVIDER, provider);

            // Set Case in store if it exists
            const caseId = window.sessionStorage.getItem(
                Constants.EPIC_CASE_IDENTIFIER
            );
            await this.$store.dispatch(StoreActions.SET_CASE_ID, caseId);

            // Perform FHIR call to retrieve Encounter ID (FIN)
            await this.$store.dispatch(
                StoreActions.READ_ENCOUNTER_FROM_FHIR_API,
                smart
            );

            if (Object.prototype.hasOwnProperty.call(smart, "state")) {
                // Set Case Documenation View based on incoming context variable
                await this.$store.dispatch(
                    StoreActions.SET_CASE_DOCUMENTATION_VIEW,
                    smart.state.tokenResponse
                );

                // Set Tenant ID and ID Token and redirect to itopia-main component for user authorization
                await this.$store.dispatch(
                    StoreActions.SET_TENANT_AND_TOKENS,
                    smart.state.tokenResponse
                );
                this.$router.push("/itopia");
            }
        } catch (resp) {
            this.$store.commit(StoreMutations.SET_HTTP_ERROR, toEhrError(resp));
            this.$router.push("/500");
        }
    }
};
