function truncateString(input, maxLength) {
    if (input.length <= maxLength) {
        return input;
    } else {
        return input.slice(0, maxLength);
    }
}

export function sanitizeCaseId(input) {
    return encodeURIComponent(truncateString(input, 30));
}
