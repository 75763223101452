var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      !_vm.itopiaReady
        ? _c("tcs-icon", {
            attrs: { name: "loading", size: "large", color: "#152933" },
          })
        : _vm._e(),
      _vm.displayWarning
        ? _c("div", { staticClass: "error-banner" }, [
            _vm._v(" " + _vm._s("Warning: " + _vm.getWarning.message) + " "),
          ])
        : _vm._e(),
      _vm.itopiaReady
        ? _c("iframe", { attrs: { src: _vm.itopiaEndpoint } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }